import './index.css';

var questions = [];
var askedQuestions = [];
var isStart = true;

async function fetchQuestions() {
    const response = await fetch(process.env.PUBLIC_URL + '/questions.txt?' + new Date().getTime());
    const text = await response.text();
    questions = text.split("\n");
}

fetchQuestions();

function loadQuestion() {
    clearLogo();

    if (isStart) {
        addQuestionToPage("Ensure everyone playing is over the age of <b>18</b><br/><br/>Please drink <b>responsibly</b><br/><br/><br/><br/><b>Accept and Play</b>");
        isStart = false;
    } else {
        if (questions === null || questions.length === 0) {
            if (askedQuestions === null || askedQuestions.length === 0) {
                addQuestionToPage("Loading...");
                fetchQuestions().then(() => {
                    loadQuestion();
                });
            } else {
                questions = askedQuestions;
                askedQuestions = [];
                return manageQuestions();
            }
        } else {
            return manageQuestions();
        }
    }
}

function manageQuestions() {
    var randomIndex = Math.floor(Math.random() * questions.length);
    var question = questions[randomIndex];
    addQuestionToPage(question);

    askedQuestions.push(question);
    questions.splice(randomIndex, 1);
}

function addQuestionToPage(textToAdd) {
    var el = document.createElement('div');
    el.setAttribute("id", "text2");
    el.innerHTML = textToAdd;
    var element = document.getElementById('text');
    if (element == null) {
        element = document.getElementById('text2');
    }
    if (el != null) {
        element.replaceWith(el);
    }
}

function clearLogo() {
    var element = document.getElementById('logo');
    if (element != null) {
        element.remove();
    }
}

export default loadQuestion;