import React, { Component } from "react";
import loadQuestion from './LoadQuestion.js';
import './index.css';
import whiteLogo from'./img/White Transparent.png';
import MaximizableView from "./components/MaximizableView.js";
import music from'./audio/background-music.mp3';

var fired = false;
const FADE_DURATION = 500;

class Clickable extends React.Component {
  constructor(props) {
    
    super(props);
    this.state = {
      question: "",
      fadeTransition : null,
      fadeState : "fade-in"
    };

    // This binding is necessary to make `this` work in the callback
    this.changeText = this.changeText.bind(this);
    this.keyPressChangeText = this.keyPressChangeText.bind(this);
    this.audio = new Audio(music);
    this.audio.loop = true;
    this.audio.volume = 0.3;
    this.isMuted = true;
  }

  
  changeText(event) {
    const timeout = setTimeout(() => {
      this.setState({
        question: loadQuestion(),
        fadeTransition : null,
        fadeState : 'fade-in'
      });
    }, FADE_DURATION);

    // Stop any existing transition
    clearTimeout(this.state.fadeTransition);

    // Update state to perform the fade out from
    // current quote
    this.setState({ 
      fadeState : 'fade-out', 
      fadeTransition : timeout 
    });
  }

  keyPressChangeText(event) {
    if (!fired) {
      fired = true;
      const timeout = setTimeout(() => {
        this.setState({
          question: loadQuestion(),
          fadeTransition : null,
          fadeState : 'fade-in'
        });
      }, FADE_DURATION);

      clearTimeout(this.state.fadeTransition);

      this.setState({ 
        fadeState : 'fade-out', 
        fadeTransition : timeout 
      });
    }
  }

  keyUp(event) {
    fired = false;
  }
  
  render() {
    return (
      <MaximizableView 
        onClick = {this.changeText} 
        onKeyPress = {this.keyPressChangeText} 
        onKeyUp = {this.keyUp}
        globalIsMuted = {this.isMuted}
        audio={this.audio}
        >
          <div id="fader" className={`fade-wrapper ${this.state.fadeState}`} 
              style={{ transitionDuration: `${FADE_DURATION}ms`}}>
            <div id="logo" className="logo">
              <img id="logoImg" className="logoImg" src={whiteLogo} />
            </div>
            <div id="text" className="text">
                <b>Daft Drinking</b>
                <br/><br/>
                Go clockwise around the room taking turns answering questions
                <br/><br/><br/>
                <b>Click anywhere to start!</b>
            </div>
          </div>
      </MaximizableView>
    );
  }
}


export default Clickable;