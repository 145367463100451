import React from "react";
import useFullscreenStatus from "./utils/useFullscreenStatus";
import isIOS from "./utils/isIOS";
import fullscreenBold from'../img/fullscreen_bold.png';
import instagramLogo from'../img/instagram-logo.png';
import muted from'../img/muted.png';
import unmuted from'../img/unmuted.png';
import {useState} from "react";

export default function MaximizableView({ children, onClick, onKeyPress, onKeyUp, globalIsMuted, audio}) {
  const maximizableElement = React.useRef(null);
  let isFullscreen, setIsFullscreen;
  let errorMessage;
  const [isMuted, setMuted] = useState(globalIsMuted);

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = "Fullscreen not supported";
    isFullscreen = false;
    setIsFullscreen = undefined;
  }

  const handleExitFullscreen = () => document.exitFullscreen();
  

  return (
    <div 
      ref={maximizableElement}
      className={`maximizable-container ${
        isFullscreen ? "fullscreen" : "default"
      }`}>          
      <div className="audio-icons">
        <button class="audio-button">
          <img 
              class="audio-button-img" 
              onClick={() => {
                if (audio.paused) {
                  audio.play()
                } else {
                  audio.pause()
                }
                setMuted(isMuted => !isMuted)
              }
            }
            src={isMuted ? muted : unmuted} 
          />
        </button>
      </div>
        <div id="background" className="maximizable-content"
        tabIndex="0"
          onClick = {onClick} 
          onKeyPress = {onKeyPress} 
          onKeyUp = {onKeyUp}>
            {children}
        </div>
        <div className="maximizable-actions">
          {!isIOS() && (
            isFullscreen ? (
              <button class="fullscreen-button">
                <img src={fullscreenBold} class="fullscreen-button-img" onClick={handleExitFullscreen} />
              </button>
            ) : (
              <button class="fullscreen-button">
                <img src={fullscreenBold} class="fullscreen-button-img" onClick={setIsFullscreen} />
              </button>
            )
          )}
       </div>
        <div className="instagram-icon">
          <button class="instagram-button">
            <a href="https://www.instagram.com/daftdrinking/" rel="noopener" target="_blank">
              <img src={instagramLogo} class="instagram-button-img" />
            </a>
          </button>
        </div>
    </div>
  );
}
