import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import loadQuestion from './LoadQuestion.js';
import Clickable from './Clickable';

// const question = (
//  <p>{loadQuestion()}</p>
// )

ReactDOM.render(
  <Clickable />,
  document.getElementById('root')
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();